import type { Tela } from '~/types/tela'
// @unocss-include
const appList: Tela.App.Specs[] = [{
    key: 'data-extraction' as const,
    title: 'Data Extraction Tool',
    description: 'Extract data from one or more documents and populate an excel document',
    icon: 'i-ph-table',
    componentName: 'DataExtraction',
    controls: {
        displayLogo: {
            type: 'switch',
            default: true,
            title: 'Display Workspace Logo',
            description: 'Your workspace logo will appear on the header of the page',
            required: true,
        },
        variable: {
            type: 'prompt-variable',
            title: 'Document Variable',
            description: 'Select the variable that the document will be extracted from',
            required: true,
        },
    },
}, {
    key: 'workstation' as const,
    title: 'Workstation',
    description: 'Create tasks, review your results and download the output',
    icon: 'i-ph-kanban',
    componentName: 'Workstation',
    controls: {
        variable: {
            type: 'prompt-variable',
            title: 'Document Variable',
            description: 'Select the variable that the document will be extracted from',
            required: true,
        },
    },
}, {
    key: 'signature-checker' as const,
    title: 'Signature or Stamp Checker',
    description: 'Extract data from one or more documents and populate an excel document',
    icon: 'i-ph-fingerprint-simple',
    available: false,
}]

export default appList
