import type { PromptVersion, UsedVersion } from 'tela-api/src/database/schemas'
import appList from '~/lib/app-list'
import type { Tela } from '~/types/tela'

export function useTelaApps() {
    const log = useLog()
    const { api } = useAPI()

    const pendingAppKey = useState<string | null>('pending-app-key', () => null)
    const currentApp = useState<Tela.App.State | null>('current-app', () => null)
    const promptApps = useState<Tela.App.State[]>('prompt-apps', () => [])

    const { currentPromptId } = usePrompts()

    async function initializeApps() {
        watchEffect(async () => {
            if (currentPromptId.value) {
                await fetchAppsByPromptId(currentPromptId.value)
            }
        })
    }

    async function fetchAppsByPromptId(promptId: string) {
        const { error, data } = await api()['prompt-application'].get({
            $query: {
                promptId,
            },
        })

        if (error) {
            log.error('Failed to fetch apps by prompt ID', error)
            return
        }

        promptApps.value = data.map(({ id, key, title, config, updatedAt, description, usedVersion, promptId }) => ({
            id,
            config,
            title,
            usedVersion,
            promptId,
            appKey: key,
            description: description ?? undefined,
            lastUpdated: new Date(updatedAt),
        }))
    }

    async function createApp(options: { key: string, promptId: string, title: string, config: Record<string, unknown>, description?: string }) {
        const { error, data } = await api()['prompt-application'].post(options)

        if (error) {
            log.error('Failed to create app', error)
            throw error
        }

        const newApp: Tela.App.State = {
            id: data.id,
            appKey: data.key,
            title: data.title,
            description: data.description ?? undefined,
            promptId: data.promptId,
            config: data.config,
            usedVersion: 'promoted',
            lastUpdated: new Date(data.updatedAt),
        }

        promptApps.value = [...(promptApps.value ?? []), newApp]

        return newApp
    }

    async function updateApp(payload: { id: string, title?: string, config: Record<string, unknown>, description?: string }) {
        log.debug('Updating app', payload)
        const { error } = await api()['prompt-application'][payload.id].patch({
            title: payload.title,
            description: payload.description,
            config: payload.config,
        })
        if (error) {
            log.error('Failed to update app', error)
            throw error
        }

        const appIndex = promptApps.value.findIndex(app => app.id === payload.id)
        if (appIndex === -1) {
            return
        }

        promptApps.value[appIndex] = {
            ...promptApps.value[appIndex],
            title: payload.title ?? promptApps.value[appIndex].title,
            description: payload.description ?? promptApps.value[appIndex].description,
            config: payload.config,
            lastUpdated: new Date(),
        }

        return promptApps.value[appIndex]
    }

    async function deleteApp(appId: string) {
        const appToDelete = promptApps.value.find(app => app.id === appId)!
        promptApps.value = promptApps.value.filter(app => app.id !== appId)

        const { error } = await api()['prompt-application'][appId].delete()

        if (error) {
            log.error('Failed to delete app', error)
            promptApps.value = [...promptApps.value, appToDelete]
            throw error
        }
    }

    async function resolveAppPromptVersion(promptId: string, usedVersion: UsedVersion) {
        if (usedVersion === 'promoted') {
            const { error, data } = await api()['prompt-version'].get({ $query: { promptId } })
            if (error) {
                throw new Error('Failed to fetch prompt versions')
            }
            const promotedVersion = Array.isArray(data) && (data as PromptVersion[]).find(v => v.promoted)
            if (!promotedVersion) {
                throw new Error('Failed to find promoted version')
            }
            return promotedVersion
        }
    }

    return {
        currentApp,
        pendingAppKey,
        promptApps,
        initializeApps,
        fetchAppsByPromptId,
        createApp,
        updateApp,
        deleteApp,
        resolveAppPromptVersion,
    }
}

export function appDefaultConfig(appKey: string) {
    const app = appList.find(a => a.key === appKey)

    if (!app || !('controls' in app)) {
        return {}
    }

    const config: Record<string, unknown> = {}
    for (const [key, control] of Object.entries(app.controls)) {
        if (control instanceof Object && 'default' in control) {
            config[key] = control.default
        }
    }

    return config
}
